var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('HeaderComp', {
    attrs: {
      "logo": 'hide',
      "back": _vm.isBack,
      "back-title": _vm.backTitle
    }
  }), _c('c-box', {
    attrs: {
      "max-w": ['inherit', '1200px'],
      "w": "100%",
      "mx": "auto",
      "px": ['20px', null],
      "pt": ['20px', '60px'],
      "pb": ['20px', '100px']
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "as": "router-link",
      "to": "/"
    }
  }, [_vm._v(" Beranda ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "as": "router-link",
      "to": "/profile"
    }
  }, [_vm._v(" Profile ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "max-w": "1200px"
    }
  }, [_vm.isClient && _vm.$route.name !== 'client.profile' ? _c('ProfileNavBottom') : _vm._e(), _c('c-flex', {
    attrs: {
      "mt": [null, '55px'],
      "ml": _vm.isClient ? [null, '20px'] : '',
      "w": _vm.isClient ? ['100%', '850px'] : '',
      "flex-grow": !_vm.isClient ? 1 : ''
    }
  }, [_c('router-view')], 1)], 1)], 1), (_vm.isClient || _vm.isNutri) && _vm.$route.name === 'client.profile' ? _c('NavBottom') : _vm._e(), _c('FooterComp')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }